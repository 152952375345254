<template>
  <my-header></my-header>
  <div class="nav">
    <div class="nav_item" v-for="item in newList" :key="item" @click="toNewDetail(item)">
      <img class="nav_item_left" :src="item.img"/>
      <div class="nav_item_right">
        <div class="title">{{ item.title }}</div>
        <div class="time">{{ item.time }}</div>
        <div class="text">{{ item.text }}</div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          @current-change="changePage"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "news",
  data() {
    return {
      newsList: [
        {
          id: 11,
          title: "打通线上线下就医环节，玉螺互联网医疗助推互联网+医疗行业发展",
          time: "2021-08-06 09:27:07",
          text: "去年年底，国家卫健委发布了《关于进一步推进“互联网+护理服务”试点工作的通知》，鼓励互联网+护理服务的发展。据国家统计局数据，截至2019年底，我国60岁及以上人数为2.543亿人，失能、半失能老人4000余万人，慢性病患者已超3亿人，对上门护理服务的需求日益增多。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_6.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '据中国互联网协会发布的《中国互联网发展报告（2021）》显示，2020年我国互联网医疗健康市场规模达到1961亿元，同比增长47%。在新冠肺炎疫情的影响下，互联网+医疗行业的发展迈上了快车道。随着政策的不断放开及完善，互联网医疗行为越来越规范，让大部分线下就医流程搬到线上成为可能，一定程度上解决了医疗资源分布不均问题，缓解了“就医难”与“就医烦”现象。',
              text_2: '玉螺互联网医疗作为实体医院的互联网+医疗服务运营专家，为实体医院搭建线上线下一体化互联网医疗服务平台，形成诊前、诊中、诊后全流程医疗服务闭环，为患者全健康周期提供服务。',
              text_3: '从预约挂号、门诊缴费，再到药品配送，患者动动手指就能完成就诊前后的一系列流程，患者无需再浪费大量时间在排队等待上，极大缓解了线下窗口排队压力，也节省了患者时间精力。对于不知道该挂什么科室的患者，可以使用智能导诊功能，患者输入病情描述，AI引擎能够快速判断潜在疾病及病情缓急，为患者精准匹配科室和医生，减少因挂错号而占用医生资源的情况发生。',
              text_4: '除了诊前、诊中服务，玉螺互联网医疗还将线上医疗服务延伸到诊后，为患者提供互联网+护理、健康管理等服务，这给高龄或失能老年人、出院后患者、康复期患者和终末期患者等行动不便的人群提供了极大便利。同时，这也为记录患者全健康周期数据奠定基础，做到患者医疗数据在院外不丢失、不断层，使得医生更好观察患者病情发展状况，给出更合适的诊疗建议，提高患者康复率。',
              text_5: '互联网医疗的优势就是能够突破时间空间的限制，尤其在防疫期间，更加突显其作用。患者通过玉螺互联网医疗搭建的互联网+医疗服务平台，就可以进行在线问诊，减少了线下接触风险，为患者和医生增加一层安全保障，也为异地就医的患者提供方便。同样地，患者数据能够被记录在电子病历上，医生能够查看到患者的历次就诊记录，对患者健康状况做出更好判断。',
              text_6: '作为深耕于互联网+医疗健康行业的玉螺互联网医疗，一直秉承着为大众健康提供优质服务的初心，不断改善平台的服务质量，提高用户的就医体验。',
              text_7: '海南玉螺信息技术有限公司是海南省政府、腾讯投资企业，是一家专注于互联网+医疗服务建设与运营的企业。依托腾讯医疗健康大数据、人工智能技术生态支撑，以及海南玉螺自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供“医疗保障”向提供“健康服务”转型。'
            },
          ]
        },
        {
          id: 12,
          title: "为线下医院赋能，玉螺互联网医疗构建线上线下一体化慢病健康服务",
          time: "2021-08-04 11:19:08",
          text: "疫情以来，线上慢病咨询需求急剧上升，通过互联网医疗慢病问诊所带来的“无接触”医疗服务，有效降低患者线下感染风险，缓解线下医院就诊压力，同时也满足疫情下慢病患者和轻症患者的居家治疗与用药需求。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_10.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '据知名咨询公司灼识咨询(China Insights Consultancy)发布的《中国互联网慢病管理行业蓝皮书》(以下简称“蓝皮书”)显示，中国的慢病人群基数较大，预计到2024年，中国的高血压、心脏病患者将达到3.8亿人，慢性呼吸系统疾病、慢性肾病、糖尿病、慢性消化系统疾病的患者也都在1亿以上，恶性肿瘤患者达500万人。而随着人口老龄化和居民健康意识的逐渐提升，加之政府对于线上平台复诊、处方药开具、医保支付的开放以及基层家庭医生的推广，中国互联网慢病管理市场将快速发展。',
              text_2: '互联网医疗经过近几年的发展与规范，加之疫情的高速催化下，慢病管理领域前景被看好的同时，行业发展也更加趋于理性。相较于争夺C端抢占市场的扩张套路，目前各大平台在慢病管理上的探索和投入更加关注精细化管理。打造慢病管理的服务闭环，注重患者、医生体验，提供更具价值服务体系，是未来慢病管理需要重点关注的环节。',
              text_3: '作为一家专注医疗领域，致力让每家医院都能实现互联网化的玉螺互联网医疗，充分发挥自身医疗资源整合优势、AI及技术分析能力，将慢病院内服务延伸至院外，实现服务闭环的同时，以患者感受及体验为中心，充分发挥医生的效能，用技术与人性化的系统管理真正帮助患者改善疾病，从而提升依从性，做每一位患者的“身边的移动健康管家”。',
              text_4: '除了诊前、诊中服务，玉螺互联网医疗还将线上医疗服务延伸到诊后，为患者提供互联网+护理、健康管理等服务，这给高龄或失能老年人、出院后患者、康复期患者和终末期患者等行动不便的人群提供了极大便利。同时，这也为记录患者全健康周期数据奠定基础，做到患者医疗数据在院外不丢失、不断层，使得医生更好观察患者病情发展状况，给出更合适的诊疗建议，提高患者康复率。',
              text_5: '玉螺互联网医疗专注于诊前、诊中、诊后的全流程管理，打造居家、社区、医院三位一体的慢病健康管理体系，从建立数据到慢病评估再到健康干预实现三位一体管理。对医院及医生而言，获取了慢病数据、提升医疗服务、转化线下就医患者；提高慢病患者用户粘性，形成长期稳定的医患关系；提高医护工作效率、社会效益及竞争力；减少医院人力资源投入和耗材成本；提高医院管理和医疗服务水平。',
              text_6: '在疫情期间，玉螺互联网医疗联合海南省人民医院上线「慢病服务小程序」，这也是自在线问诊--慢病复诊购药功能上线后，玉螺互联网医疗与海南省人民医院关于慢病服务管理的再度携手。为解决海南省人民医院慢病患者的健康管理问题，患者在完成医患签约后，可通过小程序「慢病服务小程序」轻松记录健康信息，医生在线为患者定制健康计划，足不出户就能解决患者及患者家属疑虑，让每位患者能够享受到规范治疗，病情得到有效控制及好转。',
              text_7: '玉螺互联网医疗基于“互联网+医疗”服务形式，以患者及医生体验为核心，将继续深耕数字化、全场景、全生命周期的慢病健康管理服务，不断完善线上线下一体化的诊疗模式和用户体验，普惠大众健康。',
            },
          ]
        },
        {
          id: 13,
          title: "助力健康中国，玉螺互联网医疗打造慢病防治体系",
          time: "2021-07-30 10:29:46",
          text: "随着经济社会的发展、人口老龄化进程不断加快，居民不良生活方式、食品安全等问题对健康的影响日益突出。目前，我国慢性病发病人数达到两三个亿，呈现出“患病人数多、患病时间长、医疗成本高、服务需求大”的特点。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_5.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '国家高度重视慢性病防治工作',
              text: '慢性病的防治已刻不容缓。国务院发布的《“健康中国2030”规划纲要》指出，要通过实施慢性病综合防控战略，强化慢性病筛查和早期发现，到2030年，实现全人群、全生命周期的慢性病健康管理。《中国防治慢性病中长期规划（2017－2025年）》，部署了5－10年的慢性病防治工作，降低疾病负担，提高居民健康期望寿命，努力全方位、全周期保障人民健康，为推进健康中国建设奠定坚实基础。',
            },
            {
              title: '玉螺互联网医疗打造一站式互联网+慢病管理平台',
              text: '深耕于互联网+医疗领域的玉螺互联网医疗，一直秉承着为大众提供优质健康管理服务的初心，不断丰富和完善产品功能，提供更好的行业解决方案。玉螺互联网医疗发挥自身整合医疗资源优势，充分应用人工智能、大数据分析等新一代信息技术，将慢病管理从院内延伸到院外，打通为患者服务的“最后一公里”。',
              text_2: '从“建立数据--慢病评估--健康干预--数据归档”，形成患者慢病管理数据全流程闭环，做到患者离院数据不丢失、不断层，并可以及时收集患者健康数据，让医生更好观察患者健康发展状况，适时调整诊疗方案，提高患者康复率。',
              text_3: '目前，玉螺互联网医疗搭建的互联网+慢病管理平台，在患者端可实现健康筛查、健康计划、体征监测、用药计划、医患签约、患者随访、音视频问答、知识库等八大功能。能够根据患者慢病评估报告提供风险预测结果、异常指标提醒、健康控制目标以及针对性的健康管理计划；分别对各种级别的患者制定居家管理健康计划，包括基础体征监测（如血糖、血压、体重）、饮食处方、运动处方、用药计划等，并对每种项目提供监测目标和监测建议；支持患者在移动端完成体征数据监测，当用户体征数据偏离警戒线比例时，会自动发送危急值提醒患者及医生。患者可向医生在线提问，包括检验检查报告解读、日常答疑、病情控制建议等，医生通过音视频问答在线与患者联系，并进行健康指导。',
              text_4: '未来，玉螺互联网医疗将推出更多实用功能，将医保、患者、医院、医生、药企、药店等产业链资源聚合，打造一套完整的互联网+慢病管理生态体系，形成“防--诊--治--康”全流程健康管理模式，满足患者的一站式健康管理需求，让民众享受健康生活。',
              text_5: '海南玉螺信息技术有限公司（简称“玉螺互联网医疗”）是海南省政府、腾讯投资企业，是一家专注于互联网+医疗服务建设与运营的企业。其依托腾讯医疗健康大数据、人工智能技术生态支撑，以及海南玉螺自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供“医疗保障”向提供“健康服务”转型。',
            },
          ]
        },
        {
          id: 14,
          title: "让医疗服务触手可及，玉螺互联网医疗助力互联网+医疗行业发展",
          time: "2021-07-28 14:12:46",
          text: "在新冠肺炎疫情爆发的大背景下，催生了对互联网+医疗行业的大量需求，加之政策的放开，互联网+医疗健康行业将迎来稳步发展时期。作为互联网+医疗服务建设与运营的企业，玉螺互联网医疗助力实体医院打造线上线下全流程互联网+医疗服务平台。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_12_2.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '从刷脸建档—智能导诊找医生—挂号/检查—线上缴费—医保支付—药品配送—慢病健康管理服务，玉螺互联网医疗搭建的平台可将诊前、诊中、诊后的一系列就医服务流程全覆盖，满足患者的一站式就医需求。以前初诊后需要解读检查检验结果、或药物副作用和服药等问题的患者，往往需要多次跑医院，花费大量时间精力，而患者又时常集中在同一时间段去医院就诊，造成排队时间长、患者耐心耗尽、医患矛盾频发现象。玉螺互联网医疗搭建的互联网+医疗服务平台就可以很好解决复诊患者的以上问题，通过在线问诊，医生可以通过图文或电话视频等形式对患者进行问诊；在线挂号预约可以精确到小时，患者只需要提前半个小时到医院排队等待，不仅节省时间，而且减少患者在医院聚集，降低交叉感染的风险。',
              text_2: '此外，在患者到线下医院就诊前，可以通过互联网+医疗服务平台进行智能导诊，实现医患最佳匹配。根据患者主诉，AI引擎能够智能判断潜在疾病及病情缓急，精准匹配科室和医生，导诊准确率可达95%以上，减少因患者挂错科室而耽误医生诊疗效率的情况发生。智能预问诊通过AI模拟医生问诊思路，提前采集患者病症信息，智能转录为电子病历，同步至医生端HIS系统，这样，在线下问诊时，医生能够快速了解患者病情，并给出诊疗方案，极大提高诊疗效率。',
              text_3: '海南玉螺信息技术有限公司（简称“玉螺互联网医疗”）是海南省政府、腾讯投资企业，是一家专注于互联网+医疗服务建设与运营的企业。其依托腾讯医疗健康大数据、人工智能技术生态支撑，以及海南玉螺自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供“医疗保障”向提供“健康服务”转型。',
              text_4: '玉螺互联网医疗一直秉承着科技、创新、合作、负责的态度不断前行。玉螺互联网医疗搭建的平台，不仅帮助实体医院完善互联网信息化，而且能够一站式满足患者就医、检查、住院、健康管理等需求，极大提高医护工作效率，让患者获得更好的就医服务体验，享受健康生活。未来，玉螺互联网医疗将不断完善互联网+医疗服务体系，打造医疗生态圈，为用户提供更加便捷更加满意的服务。',
            },
          ]
        },
        {
          id: 15,
          title: "玉螺互联网医疗助力互联网+慢病管理发展",
          time: "2021-07-23 10:52:16",
          text: "政策加持“互联网+慢病管理”迎来理性“风口”。近日发布的《中华人民共和国国民经济和社会发展第十四个五年规划和2035年远景目标纲要》中特别指出，“构建强大公共卫生体系”是十四五期间我国国民健康体系建设的重要目标之一，“扩大国家免疫规划，强化慢性病预防、早期筛查和综合干预”是未来的建设方向。加之在今年两会，全国人大代表、全国政协委员、行业专家等对聚焦后疫情阶段如何完善慢病管理体系、“互联网+”如何赋能慢病管理提出有效建议，并对其前景看好。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_9.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '随着工业化、城镇化、人口老龄化进程的加快，我国慢性病患病人数也呈逐年增长趋势。数据显示，我国目前因慢性病死亡占到我国居民总死亡构成的86.6%，占疾病负担的近70%，慢性病已成为严重威胁我国居民健康、影响国家经济社会发展的重大公共卫生问题。在今年的两会中，多位人大代表也提出了有关慢病管理的多项议案。可见，慢病管理的发展已成为刻不容缓的问题。',
              text_2: '慢性病作为非传染性疾病，起病原因与生活方式和环境因素有很大关联性，慢性病患者常常需要长期吃药并且定时检查身体状况，具有病程长的特点。互联网突破时间、空间限制的特点，能够很好满足慢病患者复诊频次高、居家管理健康的需求。发展互联网+慢病管理，未来慢病患者不用再为了复诊开方而花费大量时间跑去线下医院，而且能够随时监控身体状况，让患者更加注意个人的生活方式和习惯，从而让病情得到更好控制。',
              text_3: '目前，慢病管理存在以下几个业务痛点：患者依从性不高，无法按时按量依从康复治疗，离院后治疗效果差；医生不能实时掌握患者健康变化趋势，评估与诊断具有滞后性；线下随访流程复杂，较为费时费力，常常导致患者多跑腿、医护多做功的情况出现；缺乏良好的线上连接渠道，无法有效定位更多的慢病患者群体，范围较为局限；诊疗科研数据归总繁琐，医院需花费大量人力进行数据整理分析等等。',
              text_4: '针对以上慢病管理痛点，致力于为大众提供优质健康服务的玉螺互联网医疗，率先与海南省人民医院开展合作，推出互联网+慢病管理服务平台。产品定位于复诊、多端一体，并提供个性化方案，可以聚焦复诊重点人群，搭建医院、社区、医生、患者多端应用于一体的平台，并为不同特征的客户群体打造灵活的个性化慢病管理解决方案，能够满足医院、医共体慢病管理，医生科研数据采集等业务场景需求。',
              text_5: '目前，玉螺互联网医疗联合海南省人民医院推出的互联网+慢病管理服务平台，提供风险筛查、健康计划、体征监测、用药提醒、患者随访等多项功能，能够有效帮助慢病患者管理自我健康，降低发病风险。未来，玉螺互联网医疗还将推出更多实用功能，打造居家、社区、医院三位一体的慢病健康管理体系，为慢病患者提供更好的服务。',
              text_6: '作为腾讯互联网+合作伙伴，玉螺互联网医疗一直秉承科技、创新、合作、负责的态度不断前行，致力于为大众提供优质的健康管理服务。未来，玉螺互联网医疗将继续深耕医疗领域，推动互联网+医疗行业的持续健康发展。',
            },
          ]
        },
        {
          id: 16,
          title: "互联网+慢病管理千亿级市场潜力，玉螺互联网医疗能否厚积薄发？",
          time: "2021-07-20 11:21:39",
          text: "过去一年，互联网医疗在疫情催化下加速发展。被行业寄予厚望，重新回到大众视野的互联网医疗不仅解决当下就医难点，也对慢病在互联网的高效应用带来巨大想象空间。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_12.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '智研咨询发布的《2020-2026年中国慢性病用药行业市场全景调研及发展前景分析报告》显示：目前国内慢病人数达三亿多，仅高血压一项人数高达2.66亿。《中国国民健康与营养大数据报告》显示，70%国人有过劳危险，76%的白领处于亚健康状态，20%国人患慢性病，慢性病死亡率占86%。',
              text_2: '随着工业化、城镇化、人口老龄化加剧，大众生活方式、生态环境、食品安全状况等对健康的影响日益凸显，加之目前国内医疗资源供给不足、配置不均、慢病知识普及缺失，慢病发病、患病人数逐年递增。慢病已然成为严重威胁居民健康、影响国家经济社会发展的重大公共卫生问题。疫情的“冲刷”下，慢病在互联网医疗应用中更能解决“燃眉之急”，可改善医疗资源配置，实现患者全生命周期管理，并在国家政策的大力支持下，将迎来快速有序发展。',
              text_3: '作为一家专注医疗领域，致力让每家医院都能实现互联网化的玉螺互联网医疗，充分发挥自身医疗资源整合优势、AI及技术分析能力，将慢病院内服务延伸至院外，实现服务闭环的同时，以患者感受及体验为中心，充分发挥医生的效能，用技术与人性化的系统管理真正帮助患者改善疾病，从而提升依从性，做每一位患者的“身边的移动健康管家”。',
              text_4: '在此次疫情中，玉螺互联网医疗联合海南省人民医院上线「慢病服务小程序」，这也是自在线问诊--慢病复诊购药功能上线后，玉螺互联网医疗与海南省人民医院关于慢病服务管理的再度携手。为解决海南省人民医院慢病患者的健康管理问题，患者在完成医患签约后，可通过小程序「慢病服务小程序」轻松记录健康信息，医生在线为患者定制健康计划，足不出户就能解决患者及患者家属疑虑，让每位患者能够享受到规范治疗，病情得到有效控制及好转。',
              text_5: '玉螺互联网医疗专注于诊前、诊中、诊后的全流程管理，打造居家、社区、医院三位一体的慢病健康管理体系，从建立数据到慢病评估再到健康干预实现三位一体管理。对医院及医生而言，获取了慢病数据、提升医疗服务、转化线下就医患者；提高慢病患者用户粘性，形成长期稳定的医患关系；提高医护工作效率、社会效益及竞争力；减少医院人力资源投入和耗材成本；提高医院管理和医疗服务水平。',
              text_6: '玉螺互联网医疗作为腾讯投资的企业，其从成立起就具备互联网基因，借力腾讯医疗生态技术资源优势，拥有快速运用技术落地互联网医院的能力；拥有数百家医院接入组成知识库、专业准确AI导诊、AI影像、病案智能管理、诊疗风险监控等医疗AI领先技术；拥有国内外行业竞争力的专业技术人员及核心管理团队，从产品设计流程到落地执行已具备多年实践经验。深耕互联网医疗行业，玉螺互联网医疗愿景是希望成为大众最值得信赖的健康管理平台，同时在前行的进程中，将坚守用科技、创新、责任、健康高于一切的价值观为医疗领域贡献最大能量。',
            },
          ]
        },
        {
          id: 17,
          title: "玉螺互联网医疗打造互联网+全病程管理模式",
          time: "2021-07-15 15:31:52",
          text: "2020年突发的新冠肺炎疫情给民众的生活带来极大困扰，在医疗方面，出现线下诊疗渠道受阻，同时医疗资源十分紧张的情况。数据显示，2020年2月，全国医疗卫生机构诊疗人次同比下降38.2%，环比下降45.7%，这让人们看到了互联网医疗发展的必要性。互联网医疗突破时间地域限制的特点，为患者提供线上问诊、医保支付、在线开方、送药到家等服务，不仅方便了患者，而且在保障疫情防控工作有序进行方面起到不可忽视的作用。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_8.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '在后疫情时代，互联网医疗的发展还延伸到诊后服务及健康管理等内容，将服务范围进一步扩大，为出院患者、行动不便老年人、有健康管理需求等人群提供便捷医疗服务，由此形成“挂号、问诊、缴费、开方、配送、诊后服务、健康管理”的线上医疗服务闭环模式。作为实体医院的互联网+医疗服务运营专家，玉螺互联网医疗以患者为中心，建立诊前、诊中、诊后的线上线下一体化互联网+全病程管理模式，为患者提供从就诊前的预约挂号到在院就医，以及出院后的追踪等一套完善的系统方案，一站式解决患者的就医及健康管理需求，并将患者院内、院外医疗数据打通，保证数据的连续性，解决患者医疗数据信息孤岛问题，让医生更好观察患者病情发展状况，给出更加合理的诊疗方案，提高患者的康复率。',
              text_2: '互联网技术给医疗领域带来的好处远不止于此。通过医疗AI技术，能够辅助医生决策，提高诊疗效率，同时帮助医院建立专病前瞻性随访体系，进行患者大数据分析，预判患者疾病发生风险，对患者身体指标出现异常做出更快响应。通过玉螺互联网医疗为实体医院搭建的互联网医疗服务平台，能够更好采集和分析患者医疗数据，帮助医院进行患者就医统计、临床路径管理、经济指标管理等，为医生科研提供数据支撑，使临床工作与科研有机结合，实现临床效果和科研成果的相互转化。此外，互联网+全病程管理模式将三甲医院和基层医院结合，打通医院网络与管理体系，更好实现分层分级管理、精准管理，优化医疗资源配置，提高患者疾病控制率，给予患者更好的就医服务体验。',
              text_3: '海南玉螺信息技术有限公司是海南省政府、腾讯投资企业，是一家专注于互联网+医疗服务建设与运营的企业。依托腾讯医疗健康大数据、人工智能技术生态支撑，以及海南玉螺自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供“医疗保障”向提供“健康服务”转型。',
            },
          ]
        },
        {
          id: 18,
          title: "让医疗便民惠民，玉螺互联网医疗推出互联网+护理服务",
          time: "2021-07-13 17:24:56",
          text: "去年年底，国家卫健委发布了《关于进一步推进“互联网+护理服务”试点工作的通知》，鼓励互联网+护理服务的发展。据国家统计局数据，截至2019年底，我国60岁及以上人数为2.543亿人，失能、半失能老人4000余万人，慢性病患者已超3亿人，对上门护理服务的需求日益增多。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_7.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '为了给高龄或失能老年人、出院后患者、康复期患者和终末期患者等行动不便的人群提供便利的医疗服务，玉螺互联网医疗打造互联网+护理服务平台，患者通过该平台，即可预约护士，实现居家医疗护理。平台提供基础护理、慢病管理、康复护理、专项护理、健康教育等方面的护理服务，从三个维度保障护理服务走进社区家庭的安全性。',
              text_2: '在订单确认前进行病情评估，筛选安全性高的服务对象（患者情况适合进行居家护理）；护士出诊后出行轨迹全程跟踪（GPS定位），护理过程全程录音、视频记录仪记录，遇到紧急情况可一键报警，保障护士人身安全。平台上可进行预约的护士均通过资质认证申请，对护理项目严格把关（杜绝执行高风险技术服务）；对服务对象进行线上、线下双评估，确保患者得到安全合理的护理服务。在医疗服务安全方面，拥有完善的护理服务流程，落实患者和护士相关知情告知制度，对护理过程全程记录，患者可对服务质量进行在线反馈等等，从而将安全风险的管控贯穿患者从预约下单到服务反馈的全过程。',
              text_3: '作为实体医院的互联网+医疗服务运营商，玉螺互联网医疗一直秉承着为大众健康提供优质服务的初心，打造互联网+医疗服务平台，为出院患者或罹患疾病且行动不便的特殊人群提供互联网+护理服务。平台将护士与家庭医疗需求打通，让人们居家就能享受医疗健康，不仅节省患者就医时间精力、减轻家庭看护负担，而且将护理资源优化配置整合，为护士创造更多的就业机会和岗位。',
              text_4: '未来，玉螺互联网医疗还将推出更多互联网+医疗服务，从患者角度出发，提供更多实用功能，改善患者就医服务体验，让民众享受健康生活。'
            },
          ]
        },
        {
          id: 19,
          title: "提高居民健康水平，玉螺互联网医疗打造慢病管理模式",
          time: "2021-07-09 14:16:32",
          text: "随着经济社会的发展，人口老龄化、城镇化、工业化进程加快以及不健康的生活方式等因素，慢性病逐渐成为影响居民健康状况的一大威胁。数据显示，我国慢性病发病人数达到两三个亿，慢性病导致的疾病负担占总疾病负担的近70%，导致的死亡占我国总死亡人数的86.6%。重视慢性病防治，提高慢病管理水平已刻不容缓。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_8_2.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '致力于为大众健康服务，玉螺互联网医疗积极探索互联网+慢病管理新模式，打造“线上+线下”“院内+院外”双循环的互联网+慢病管理模式。将医疗服务从线下延伸到线上，方便医患实时沟通，提高医护工作效率；将慢病管理从院内延伸到院外，让患者医疗数据不断层，医生能够更好观察慢病患者病情发展状况，给出更好的诊疗方案，增加患者康复率。',
              text_2: '通过建立电子健康档案，系统能够采集患者主动上传的健康自测数据、在院内院外的诊疗数据等信息，不仅方便患者及家属随时查看，而且医生可以观察患者健康数据变化，及时查看异常信息，调整诊疗方案。玉螺互联网医疗搭建的互联网+慢病管理平台，还为患者提供智能化的疾病风险评估、居家管理健康计划及长期随访等服务，覆盖患者“预防--治疗--康复”的全周期健康管理，让慢病患者获得更好的医疗服务，控制病情发展。',
              text_3: '玉螺互联网医疗打造的互联网+慢病管理平台，提供线上慢病诊疗及全流程健康管理及咨询服务，不仅改善患者就医体验，帮助患者更好进行自我健康管理，而且能够提高医护工作效率，减少不必要的人力和耗材投入，增强医院的竞争力及效益。未来，玉螺互联网医疗将推出更多实用功能，将医保、患者、医院、医生、药企、药店等产业链资源聚合，打造一套完整的互联网+慢病管理生态体系，满足患者的一站式健康管理需求，让民众享受健康生活。',
              text_4: '海南玉螺信息技术有限公司是海南省政府、腾讯投资企业，是一家专注于互联网+医疗服务建设与运营的企业。依托腾讯医疗健康大数据、人工智能技术生态支撑，以及海南玉螺自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供“医疗保障”向提供“健康服务”转型。'
            },
          ]
        },
        {
          id: 20,
          title: "让服务可及性更高，玉螺互联网医疗推出互联网+护理服务",
          time: "2021-07-06 17:08:19",
          text: "据国家统计局数据显示，截止2019年底，我国60岁及以上人口为2.54亿人，占总人口的18.1%，65岁及以上人口为1.76亿人，占12.6%。其中失能、半失能老年人4000余万。随着人口老龄化进程加速，失能、高龄、空巢老年人的护理需求日益增长，母婴、慢性病等人群的护理需求也不断扩大。为了满足民众日益增长的护理服务到家需求，2020年底，国家卫健委发布了《关于进一步推进“互联网+护理服务”试点工作的通知》，引起了广泛关注，自2021年1月1日起，将“互联网+护理服务”试点范围扩大至全国。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_3.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '为了响应国家政策，玉螺互联网医疗联合实体医院推出互联网+护理服务，用户在互联网医院平台上下单，即可享受专业护理人员上门服务。“线上下单、线下护理”模式将护理人员与家庭需求更好地连接起来，让医疗资源得到充分利用，患者需求得到更好满足，将医疗服务延伸到家庭的“最后一公里”打通，极大节省患者及其家属时间精力，让护理过程更加舒心、省心。',
              text_2: '玉螺互联网医疗推出的互联网+护理服务拥有完善的服务流程，从订单确认前的知情同意书，到护理过程全程监控，再到订单完成后的服务反馈，将安全保障贯穿护理服务全流程。护理人员皆通过相应的资质审核及培训，服务项目也进行严格限制（严禁执行高风险技术服务），护理过程全程录音、视频记录仪记录，遇到突发状况可选择一键报警等等，为护理双方的安全保驾护航。此外，借助互联网信息化手段，玉螺互联网医疗帮助实体医院优化护理服务流程和服务模式，以问题需求为导向，提供具备护理业务运行监控、护理人员调配、数据分析等功能的护理管理平台，提高护理工作效率，改善服务质量，为实现护理管理的科学化、精细化提供信息技术支撑。',
              text_3: '玉螺互联网医疗推出的互联网+护理服务，将智慧医疗与护理相结合，支持护理人员院内外工作协同、患者信息连续性跟踪，不仅提高护理人员工作效率，优化医疗资源配置，增加实体医疗机构收入，而且让患者获得更好的就医服务体验，提高生活质量。',
              text_4: '海南玉螺信息技术有限公司是海南省政府、腾讯投资企业，是一家专注于互联网+医疗服务建设与运营的企业。依托腾讯医疗健康大数据、人工智能技术生态支撑，以及海南玉螺自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供“医疗保障”向提供“健康服务”转型。',
            },
          ]
        },
        {
          id: 21,
          title: "玉螺互联网医疗打造慢病管理服务体系",
          time: "2021-07-01 15:28:49",
          text: "新冠肺炎疫情的爆发，不仅提高了民众对线上就医的接受度，互联网医疗行业相关政策也逐渐由框架确定过渡到细节设计阶段。由此，互联网医疗行业的发展进入加速期。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_11.png",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '据数据显示，中国的慢病人群基数庞大，预计到2024年，中国的高血压、心脏病患者将达到3.8亿人，慢性呼吸系统疾病、慢性肾病、糖尿病、慢性消化系统疾病的患者也都在1亿人以上。在这一庞大的数据背后，也意味着慢病管理拥有巨大的潜在市场。据统计，国内互联网慢病管理市场规模在2019年已达694亿元人民币，这一数字在2024年将会增长到2177亿元，年复合增长率高达25.7％，预计潜在市场规模为3000亿元。民众日益增长的慢病管理需求让互联网医疗企业加速进场。',
              text_2: '作为实体医院的互联网+医疗服务运营专家，玉螺互联网医疗以患者为中心，致力于为大众提供优质的医疗健康服务。在政策的支持下，玉螺互联网医疗打造的慢病管理服务体系能够实现从在线问诊、续方购药，到医保支付等服务全流程闭环，将医、药、险环节打通，让患者足不出户就可以享受到问诊不排队、购药不出门、支付可医保等便捷就医服务。利用互联网工具的优势，玉螺互联网医疗将医疗服务资源整合，并重构医患关系链，打造患者与医生在线实时交流平台，通过健康筛查、远程问诊、患者随访、复诊续方、知识科普等手段来提升患者的依从性，从而改善治疗效果，大大提高患者康复率。',
              text_3: '此外，玉螺互联网医疗打造的慢病管理服务平台为医护提供了便捷的管理工具，通过患者异常数据提醒、结构化数据分析、智能随访计划、复诊用药提醒等，减少了医护不必要的时间成本支出，提高了医护工作效率，极大减轻医护工作负担，让医护有更多的时间、更加耐心为患者诊疗、答疑解惑，从而让患者获得更好的就医服务体验，缓解医患之间的矛盾。玉螺互联网医疗打造的慢病管理服务平台是基于实体医院搭建，将实体医院的医生与患者连接起来，能够改善医院的服务管理，增强医院的核心竞争力。',
              text_4: '海南玉螺信息技术有限公司是海南省政府、腾讯投资企业，是一家专注于互联网+医疗服务建设与运营的企业。依托腾讯医疗健康大数据、人工智能技术生态支撑，以及海南玉螺自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供“医疗保障”向提供“健康服务”转型。',
            },
          ]
        },
        {
          id: 1,
          title: '玉螺互联网医疗，让互联网医疗大有可为',
          time: '2021-06-30 09:48:32',
          text: '在新冠肺炎疫情爆发的背景下，互联网医疗服务行业迎来了一次非常好的发展契机。互联网医疗打破时间地域的限制，让人们居家就能享受到医疗服务，实现了医患间的“物理隔离”，大大降低了交叉感染的风险，为疫情防控做出了重大贡献。作为实体医院的互联网+医疗服务运营专家，玉螺互联网医疗助力互联网医疗行业发展，让互联网技术在医疗领域发挥出更大价值。',
          img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_1.jpg',
          hint: '图片来源于网路',
          contentList: [
            {
              title: '移动端就能进行预约，优化线下就医流程',
              text: '玉螺互联网医疗搭建的互联网+医疗服务平台，让患者通过移动端就可以完成就医的大部分流程。如患者在去线下医院就诊前，可以提前通过手机进行网上挂号、预约诊疗，查看排班医生信息，选择合适的时间就诊，不用再花费大量时间去医院排队拿号；等待就诊期间使用排队叫号功能，能够随时查看当前排队人数，避免过号漏号；在医生开具处方单后，可以选择在线缴费、药品配送到家等服务，即看完病后患者可以直接离开，大大缩减在医院的停留时间。不仅极大节省患者时间精力，提高患者就医服务体验，而且减少了医院人群聚集，降低交叉感染的风险。'
            },
            {
              title: '将优质医疗资源下沉，缓解地域分配不均问题',
              text: '目前，优质医疗资源大多集中在大城市、三甲医院，造成三甲医院人满为患，不能很好发挥出科研、救治危急重症的作用。玉螺互联网医疗搭建的互联网医疗服务体系，利用互联网手段，建立医联体体系数据共享平台，将区域内医疗资源整合，打通各区域医疗信息闭塞，帮助医院分流患者，让小病下沉到基层医疗机构，危重病、疑难病转诊到大中型医院，从而改善基层医疗机构资源闲置现象，达到释放大中型医院医疗资源的目的，让医疗资源得到合理配置，各医疗机构医疗业务高效运转。'
            },
            {
              title: '利用人工智能大数据，辅助医疗决策活动',
              text: '通过人工智能大数据、专业准确的AI 导诊、AI 影像、诊疗风险监控等医疗领先技术，玉螺互联网医疗提供的互联网+医疗便民服务平台为医生提供临床决策支持，如用药分析、药品不良反应、治疗效果相关性分析等，提高临床决策精准度及效率。同时整合医疗大数据，保障患者医疗数据在从院内延伸到院外时的连续性，为居民提供更加个性化的健康保健指导，如提供高血压、糖尿病等慢性病干预、体征监测等，让民众更好实现居家健康管理，享受健康生活。',
              text_2: '海南玉螺信息技术有限公司是海南省政府、腾讯投资企业，是一家专注于互联网+医疗服务建设与运营的企业。依托腾讯医疗健康大数据、人工智能技术生态支撑，以及海南玉螺自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供“医疗保障”向提供“健康服务”转型。'
            }
          ]
        },
        {
          id: 2,
          title: "让民众享受健康生活，玉螺互联网医疗提供互联网+慢病管理服务",
          time: "2021-06-29 15:10:24",
          text: "据《健康管理蓝皮书：中国健康管理与健康产业发展报告（2019）》指出，我国现有确诊的慢性病患者近3亿人，慢性病已成为危害居民健康的首要因素。\n" +
            "慢性病的特点是不构成传染，但长期积累会形成疾病形态损害，严重影响人们的身心健康。因此，早发现、早干预对于慢性病的防治是十分有效的。随着居民健康管理意识的提升，对于利用互联网技术来提高慢病管理效率的接受程度逐步提高，未来，互联网+慢病管理行业将有很大的蓝海。",
          img: "https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/img/news_detail_2.jpg",
          hint: "图片来源于网络",
          contentList: [
            {
              title: '',
              text: '针对传统慢病管理模式患者依从性差、数据记录难、缺乏专业的慢病管理指导等痛点，玉螺互联网医疗搭建的互联网+慢病管理服务平台，利用人工智能、大数据等新一代信息技术，实现对患者数据的采集和监测，并利用模型进行智能分析，来发现患者中长期的慢病风险，让医生更好地观察患者疾病变化，给出个性化的诊疗方案。同时，患者可以随时在平台上查看自己的体征数据、医生给出的健康计划等，并且能够查阅相关专业的健康知识，科学、合理的进行用药、膳食，让患者更好实现居家自我健康管理。',
              text_2: '据亿欧智库发布的《2020年中国互联网慢病管理白皮书》指出，预计到2025年，互联网慢病管理市场规模将达2357亿元，渗透率将增长至37%，提供慢病管理服务将成为互联网医疗行业中很有潜力的一大市场。玉螺互联网医疗依托自身强大的技术团队及医疗资源，搭建的互联网+慢病管理体系将打通上下游医疗产业链，将医保、患者、医院、医生、药企、药店等产业链资源聚合，形成“早筛、治疗、管理”一体化模式，让平台更好实现慢病预防、干预、实时反馈等作用，一站式满足患者的健康管理需求。',
              text_3: '海南玉螺信息技术有限公司是海南省政府、腾讯投资企业，是一家专注于互联网+医疗服务建设与运营的企业。依托腾讯医疗健康大数据、人工智能技术生态支撑，以及海南玉螺自主研发的实体医院互联网+医疗产品优势，致力于为实体医院提供互联网+医疗服务解决方案及运营服务，提升医疗服务效率，助力实体医院从提供“医疗保障”向提供“健康服务”转型。',
            },
          ]
        },

      ],
      list: [],
      page: 1,
      pageSize: 4,
      total: '',
      newList: []
    };
  },
  methods:{
    toNewDetail(item){
      localStorage.setItem('item',JSON.stringify(item))
      this.$router.push({
        name: 'newsDetail',
        params: {}
      })
    },
    changePage(currPage){
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.page = currPage
      this.total = this.newsList.length
      switch (this.page) {
        case 1:
          this.newList = this.list[0]
          break
        case 2:
          this.newList = this.list[1]
          break
        case 3:
          this.newList = this.list[2]
          break
        case 4:
          this.newList = this.list[3]
          break
      }
    },
    dataMock(){
      let arrLength = this.newsList.length // 数组长度
      let num = 4 // 每页显示4条
      let index = 0;
      for (let i = 0; i < arrLength; i++){
        if(i % num === 0 && i !== 0){
          this.list.push(this.newsList.slice(index, i))
          index = i
        };
        if((i + 1) === arrLength){
          this.list.push(this.newsList.slice(index, (i + 1)))
        }
      }
      console.error('123', this.list)
      this.changePage(1)
    },
  },
  created(){
  },
  mounted() {
    this.dataMock()
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>

<style lang="stylus" scoped>
.nav {
  display flex
  flex-flow column
  box-sizing border-box
  padding 60px 140px

  .nav_item{
    display flex
    margin-bottom 40px
    cursor pointer
    .nav_item_left{
      width 308px
      height 230px
      border-radius 4px
    }
    .nav_item_right{
      display flex
      flex-flow column
      margin-left 40px
      width 70%
      .title{
        font-size 32px
        font-family Source Han Sans CN
        font-weight 500
        line-height 46px
        color #343A40
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      }
      .time{
        font-size 16px
        font-family Source Han Sans CN
        font-weight 400
        line-height 19px
        color #6C757D
        margin-top 20px
      }
      .text{
        font-size 22px
        font-family Source Han Sans CN
        font-weight 400
        line-height 28px
        margin-top 30px
      }
    }
  }


  .pagination{
    display flex
    justify-content flex-end
  }
}
</style>
